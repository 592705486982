import $ from 'jquery';
import 'jquery.easing';


export default {
  name: "barra",
  props: {
    msg: String,
  },


  mounted(){

    let dt =this;

    $("#l_about").on("click",()=>{
      console.log("a")
      dt.scrollTo("#about");
    })

  },

  methods: {
    scrollTo(refName) { 
      console.log(refName)
      let scrolltoOffset = $("#header").outerHeight() - 17;
   
      if ($(refName).length) {
        var scrollto = $(refName).offset().top - scrolltoOffset;

        $("html, body").animate(
          {
            scrollTop: scrollto,
          },
          1500,
          "easeInOutExpo"
        );

        if ($("body").hasClass("mobile-nav-active")) {
          $("body").removeClass("mobile-nav-active");
          $(".mobile-nav-toggle i").toggleClass(
            "icofont-navigation-menu icofont-close"
          );
          $(".mobile-nav-overly").fadeOut();
        }
      }
    },
  },
};
