<template>
  <div class="home">
    <barra />

    <main id="main">
      <otros />
    </main>

    
  </div>
</template>

<script>
import barra from "@/components/barra/index.vue";
import otros from "@/components/otros/index.vue";

export default {
  name: "otrosbp",
  components: {
    otros,
    barra,
  },
};
</script>
