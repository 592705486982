

export default {
  name: 'serviciosMain',



  mounted() {



    console.log("s" + this.$route.params.id)

  
    document.getElementById("s" + this.$route.params.id).scrollIntoView({block: "start", behavior: "smooth"});



  }
}