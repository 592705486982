import $ from "jquery";
import "jquery.easing";

export default {
  name: "barrapagos",
  props: {
    msg: String,
  },

  data() {
    return {
      user_data: { dni: "-", nombre: "-" },
    };
  },
  methods: {

    delog() {
      this.preload_in();
   
        this.axios
          .post("/server/", {
            a: "delog",
            b: "dslog",
            bkey: this.rmatch,
          })
          .then((response) => {
            if (response.data.status == 1) {
              window.location.href = "/bp";
            }else{
              this.preload_out();
            }
          })
          .catch((err) => {
            this.preload_out();
            alert("Error al procesar la peticion" + err);
          });
      
    },
    get_user_data() {
      this.axios
        .post("/server/", {
          a: "usrdt",
          b: this.rmatch,
          bkey: this.rmatch,
        })
        .then((r) => {
          if (r.data.status == 1) {
            this.user_data = r.data.data[0];
          }else{
            window.location.href = "/bp";
          }
        });
    },

    scrollTo(refName) {
      let scrolltoOffset = $("#header").outerHeight() - 17;

      if ($(refName).length) {
        var scrollto = $(refName).offset().top - scrolltoOffset;

        $("html, body").animate(
          {
            scrollTop: scrollto,
          },
          1500,
          "easeInOutExpo"
        );
      }
    },
  },

  mounted() {
    this.get_user_data();
  },
};
