export default {
  name: 'registrfs',
  data() {
    return {
      tipe: this.$route.params.tipe,
    }
  },

  props: {
    msg: String
  }
}