import $ from 'jquery';
import 'jquery.easing';

export default {
  name: 'hero',
  props: {
    msg: String
  }

  ,
  methods: {
    scrollTo(refName) {
      
      let scrolltoOffset = $("#header").outerHeight() - 17;
   
      if ($(refName).length) {
        var scrollto = $(refName).offset().top - scrolltoOffset;

        $("html, body").animate(
          {
            scrollTop: scrollto,
          },
          1500,
          "easeInOutExpo"
        );
      }
    },
  }
}