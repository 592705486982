export default {
  name: "registro",
  props: {},

  data() {
    return {
      pass_type: "password",
      data_form: {
        dni: "",
        nombre: "",
        telefono: "",
        email: "",
        direccion: "",
        pass: "",
        pass_require: "",
      },

      control_elements: [false, false, false, false, false, false, false],
      validate: [false, false, false, false, false, false, false],
    };
  },

  methods: {
    registrar() {

      this.preload_in();

      if (this.validador() == 0) {
        this.axios
          .post("/server/", {
            a: "regis",
            bkey: this.rmatch,
            f: this.data_form,
          })
          .then((response) => {

            if (response.data.status == 1) {

              window.location.href = '/rok';

            } else if (response.data.status == 1062) {
              window.location.href = '/rfs/exus';
            } else {
              window.location.href = '/rfs/fsmotv';
            }
            this.preload_out();
          }).catch((err) => {
            this.preload_out();
            alert("Error al procesar la peticion" + err);
          });
      }


    },

    validador() {
      let d = 7;
      for (let i = 0; i < this.validate.length; i++) {
        if (this.control_elements[i] && !this.validate[i]) {
          d--;
        }
      }

      return d;
    },
    focus_element(i) {
      this.control_elements[i] = true;
    },

    show_pass() {
      if (this.pass_type == "password") {
        this.pass_type = "text";
      } else {
        this.pass_type = "password";
      }
    },
    chk_dni() {
      let ret = false;

      if (this.control_elements[0] && this.data_form.dni.length < 7) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[0] = ret;

      return ret;
    },
    chk_nombre() {
      let ret = false;
      if (this.control_elements[1] && this.data_form.nombre.length < 5) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[1] = ret;

      return ret;
    },
    chk_telefono() {
      let ret = false;
      if (this.control_elements[2] && this.data_form.telefono.length < 9) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[2] = ret;

      return ret;
    },
    chk_email() {
      let ret = false;
      if (
        this.control_elements[3] &&
        (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.data_form.email
        ) ||
          this.data_form.email.length < 6)
      ) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[3] = ret;

      return ret;
    },
    chk_direccion() {
      let ret = false;
      if (this.control_elements[4] && this.data_form.direccion.length < 5) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[4] = ret;

      return ret;
    },

    chk_pass() {
      let ret = false;
      let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/;

      if (this.control_elements[5] && !this.data_form.pass.match(passw)) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[5] = ret;

      return ret;
    },

    chk_pass_require() {
      let ret = false;
      if (this.control_elements[6] && this.data_form.pass_require != this.data_form.pass) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[6] = ret;

      return ret;
    },
  },
};
