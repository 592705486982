export default {
  name: "restorepass",
  props: {},

  data() {
    return {
      pass_type: "password",

      is: 1,

      recuperated: 0,

      restore_id: this.$route.params.id,
      user_rest: '',
      data_form: {

        old_pass: "",
        pass: "",
        pass_require: "",

      },

      control_elements: [false, false, false],
      validate: [false, false, false],
    };
  },

  mounted() {

  },


  methods: {
    recuperar() {

      this.preload_in();

      if (this.validador() == 0) {
        this.axios
          .post("/server/", {
            a: "renewp",
            bkey: this.rmatch,
            f: this.data_form,
          })
          .then((response) => {

            this.recuperated = response.data.status;


            if (this.recuperated==666) {
              
              window.location.href = "/bp";
            }

            this.preload_out();
          }).catch((err) => {
            this.preload_out();
            alert("Error al procesar la peticion" + err);
          });
      }


    },



    validador() {
      let d = 3;
      for (let i = 0; i < this.validate.length; i++) {
        if (this.control_elements[i] && !this.validate[i]) {
          d--;
        }
      }

      console.log(d)
      return d;
    },
    focus_element(i) {
      this.control_elements[i] = true;
    },

    show_pass() {
      if (this.pass_type == "password") {
        this.pass_type = "text";
      } else {
        this.pass_type = "password";
      }
    },


    chk_old_pass() {
      let ret = false;

      if (this.control_elements[0] && !this.data_form.old_pass != '') {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[0] = ret;

      return ret;
    },


    chk_pass() {
      let ret = false;
      let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/;

      if (this.control_elements[1] && !this.data_form.pass.match(passw)) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[1] = ret;

      return ret;
    },

    chk_pass_require() {
      let ret = false;
      if (this.control_elements[2] && this.data_form.pass_require != this.data_form.pass) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[2] = ret;

      return ret;
    },


  },
};
