export default {
  name: "login",
  props: {
    msg: String,
  },
  data() {
    return {
      pass_type: "password",
      status_login: 1,
      data_form: {
        dni: "",
        pass: "",
      },
      control_elements: [false, false],
    };
  },

  mounted() {
    this.islog();
  },

  methods: {

    disabled_in(){
     return(this.data_form.dni=='' || this.data_form.pass=='');

    },
    islog() {
      this.preload_in();
   
        this.axios
          .post("/server/", {
            a: "islog",
            b: "dslog",
            bkey: this.rmatch,
          })
          .then((response) => {
            if (response.data.status == 1) {
              window.location.href = "/pagos";
            }else{
              this.preload_out();
            }
          })
          .catch((err) => {
            this.preload_out();
            alert("Error al procesar la peticion" + err);
          });
      
    },
    ingresar() {
     
      if (this.validador()) {
        this.preload_in();
        this.axios
          .post("/server/", {
            a: "lgin",
            b: "rdnms",
            bkey: this.rmatch,
            f: this.data_form,
          })
          .then((response) => {
            if (response.data.status == 1) {
              window.location.href = "/pagos";
            } else {
              this.preload_out();
              this.status_login = response.data.status;
            }
          })
          .catch((err) => {
            this.preload_out();
            alert("Error al procesar la peticion" + err);
          });
      }
    },

    validador() {
      return this.data_form.pass != "" && this.data_form.dni != "";
    },

    show_pass() {
      if (this.pass_type == "password") {
        this.pass_type = "text";
      } else {
        this.pass_type = "password";
      }
    },

    focus_element(i) {
      this.control_elements[i] = true;
    },

    chk_dni() {
      let ret = false;

      if (this.control_elements[0] && this.data_form.dni == "") {
        ret = true;
      } else {
        ret = false;
      }

      return ret;
    },

    chk_pass() {
      let ret = false;

      if (this.control_elements[1] && this.data_form.pass == "") {
        ret = true;
      } else {
        ret = false;
      }

      return ret;
    },
  },
};
