<template>
  <div class="home">
    <barra />
    <div>
      <main id="main">
        <section id="breadcrumbs" class="breadcrumbs">
          <div class="container">
            <ol>
              <li><a href="/">Multiconex</a></li>
              <li>Archivos</li>
            </ol>
            <h2>
              <a href="/drive?path=">Archivos</a>
              <a href="" class="alinjk" v-for="(c, i) in pathsPlitd" :key="i">
                / {{ c }}</a
              >
            </h2>
          </div>
        </section>

        <section id="values" class="values">
          <div class="container">
            <div class="row">
              <div class="col-md-12" v-for="(c, i) in carpeta.dirs" :key="i">
                <div class="carpeta" @click="nextDir(c)">
                  <i class="bx bx-folder"></i>
                  <h5>{{ c }}</h5>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-11" v-for="(c, i) in carpeta.files" :key="i">
                <div>
                  <a
                    class="files"
                    download
                    :href="
                      '/server/drive/file?file=' + carpeta.path + '&name=' + c
                    "
                  >
                    <i class="bx bx-file"></i>
                    <h5>{{ c }}</h5></a
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import barra from "@/components/barra/index.vue";

export default {
  name: "servicios",
  components: {
    barra,
  },

  data() {
    return {
      carpeta: { dirs: [], files: [], path: "" },
    };
  },
  mounted() {
    this.path();
  },

  computed: {
    pathsPlitd() {
      return this.carpeta.path.split("/").filter((e) => e != "");
    },
  },

  methods: {
    path() {
      this.carpeta = { dirs: [], files: [], path: "" };
      this.axios
        .post("/server/drive/", {
          path: this.$route.query.path,
        })
        .then((r) => {
          this.carpeta = r.data;
        });
    },

    nextDir(c) {
      window.location = "/drive?path=" + this.$route.query.path + "/" + c;
    },
  },
};
</script>

<style scoped>
.carpeta h5,
.files h5 {
  margin-left: 25px;
}

.carpeta i,
.files i {
  font-size: 25px;
}

.carpeta {
  display: flex;
  flex-direction: row;
  font-size: 25px;
  margin: 10px;
  background: #fff1f1;
  width: 100%;
  padding: 16px 10px 10px 10px;
  padding-top: 15px;
  border-radius: 10px;
  cursor: pointer;
}
.carpeta:hover {
  background-color: #e5e5e5;
}

.files {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin: 10px;
  background: rgb(244, 244, 244);
  width: 100%;
  padding: 16px 10px 10px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.files:hover {
  background-color: #e5e5e5;
}

.alinjk {
  color: #525252;
}
</style>
