<template>
  <div class="home">
    <barra />

    <main id="main">
      <registrfs />
    </main>

    
  </div>
</template>

<script>
import barra from "@/components/barra/index.vue";
import registrfs from "@/components/botonPago/registrfs/index.vue";

export default {
  name: "registrfsv",
  components: {
    barra,
    registrfs,
  },
};
</script>
