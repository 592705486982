import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import $ from 'jquery';
import 'jquery.easing';
 
 

Vue.config.productionTip = false; 
 
 
axios.defaults.withCredentials = true

Vue.prototype.axios = axios;
Vue.prototype.rmatch = Math.random()
  .toString(36)
  .substring(9);

Vue.prototype.$ = $;


Vue.prototype.preload_in = function () {

  $("#preloader")
    .delay(100)
    .fadeOut("slow", function () {
      $("#preloader").css("display", "block");
    });
}


Vue.prototype.preload_out = function () {

  $("#preloader")
    .delay(100)
    .fadeOut("slow", function () {
      $("#preloader").css("display", "none");
    });
}


Vue.prototype.formatterNumber = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',

});



Vue.prototype.mobileCheck = function () {
  let check = false;


  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     check = true;
  }

  return check;

}

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
