export default {
  name: "restorepass",
  props: {},

  data() {
    return {
      pass_type: "password",

      is: 0,

      recuperated: 0,

      restore_id: this.$route.params.id,
      user_rest: '',
      data_form: {

        pass: "",
        pass_require: "",
      },

      control_elements: [false, false],
      validate: [false, false],
    };
  },

  mounted() {
    this.consultar_restore();
  },


  methods: {
    recuperar() {

      this.preload_in();

      if (this.validador() == 0) {
        this.axios
          .post("/server/", {
            a: "resetp",
            bkey: this.rmatch,
            f: this.data_form,
          })
          .then((response) => {

            if (response.data.status == 1) {
              this.recuperated = 1;

            } else {
              this.recuperated = 2;
            }

            this.preload_out();
          }).catch((err) => {
            this.preload_out();
            alert("Error al procesar la peticion" + err);
          });
      }


    },


    consultar_restore() {

      this.preload_in();


      this.axios
        .post("/server/", {
          a: "restid",
          bkey: this.rmatch,
          di: this.restore_id,
        })
        .then((response) => {


          this.is = response.data.status;

          if (response.data.status == 1) {


            this.user_rest = response.data.name;


          }


          this.preload_out();
        }).catch((err) => {
          this.preload_out();
          alert("Error al procesar la peticion" + err);
        });



    },

    validador() {
      let d = 2;
      for (let i = 0; i < this.validate.length; i++) {
        if (this.control_elements[i] && !this.validate[i]) {
          d--;
        }
      }

      return d;
    },
    focus_element(i) {
      this.control_elements[i] = true;
    },

    show_pass() {
      if (this.pass_type == "password") {
        this.pass_type = "text";
      } else {
        this.pass_type = "password";
      }
    },

    chk_pass() {
      let ret = false;
      let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/;

      if (this.control_elements[5] && !this.data_form.pass.match(passw)) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[5] = ret;

      return ret;
    },

    chk_pass_require() {
      let ret = false;
      if (this.control_elements[6] && this.data_form.pass_require != this.data_form.pass) {
        ret = true;
      } else {
        ret = false;
      }
      this.validate[6] = ret;

      return ret;
    },


  },
};
