<template>
  <div class="home">
    <barra />

    <main id="main">
         <registro />
    </main>

 
  </div>
</template>

<script>
import barra from "@/components/barra/index.vue";
import registro from "@/components/botonPago/registro/index.vue";

export default {
  name: "registrobp",
  components: {
    registro,
    barra,
  },
};
</script>
