<template>
  <div class="home">
    <barra />

    <main id="main">
      <registrOk />
    </main>

    
  </div>
</template>

<script>
import barra from "@/components/barra/index.vue";
import registrOk from "@/components/botonPago/registrOk/index.vue";

export default {
  name: "registrokv",
  components: {
    barra,
    registrOk,
  },
};
</script>
