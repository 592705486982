<template>
  <div class="home">
    <barra />

    <main id="main">
         <userK />
    </main>

 
  </div>
</template>

<script>
import barra from "@/components/pagos/barra/index.vue"; 
import userK from "@/components/pagos/user/key/index.vue"; 

export default {
  name: "userKey",
  components: {
    userK,
    barra,
  },
};
</script>
