<template>
  <div class="home">
    <barra />

    <main id="main">
      <detalle/>
    </main>

    
  </div>
</template>

<script>
import barra from "@/components/pagos/barra/index.vue"; 
import detalle from "@/components/pagos/detalleDeudas/index.vue"; 

export default {
  name: "detalleDeudas",
  components: {
 
    barra,
    detalle
  },
};
</script>
