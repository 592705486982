export default {
  name: "detalleDeudas",

  data() {

    return {
      numero_deuda: this.$route.params.id,
      monto_pagar: 0,
      monto_pagar_dec: 0,
      monto_maximo_cupon: 0,

      detalle_deuda: {
        cliente: "",
        detalle: "",
        empresa: "",
        estado: 0,
        fecha_deuda: "",
        id: 0,
        monto_deuda: 0,
        cuotas: [],
        nro_documento: "",
        nro_producto: "",
        tipo_producto: ""
      },
    };
  },

  mounted() {
    
    this.consultar_detalle_deuda();

  },

  methods: {
   

    $_r(nm) {
      return Math.ceil(nm * 100) / 100;
    },

    $_(nm) {
      return Math.round(nm * 100) / 100;
    },


    generar_cupon(ele, monto) {

      ele.target.classList.remove('btn-success');
      ele.target.classList.add('btn-primary');
      ele.target.setAttribute("disabled", true);

      this.preload_in();

      this.axios
        .post("/server/", {
          a: "gecupp",
          m: monto,
          b: this.numero_deuda,
          fsrange: this.rmatch,
          bkey: this.rmatch,
        })
        .then((r) => {

          if (r.data.status == 1) {

            ele.target.classList.remove('btn-primary');
            ele.target.classList.add('btn-success');


            if (this.mobileCheck()) {
              window.location.href = r.data.link;
            } else {
              window.open(r.data.link);
            }

          } else if (r.data.status == 404) {
            window.location.href = "/bp";
          } else {
            alert("Error al generar cupon.");

          }


          ele.target.removeAttribute("disabled");
          this.preload_out();
        }).catch((e) => {

          alert("Error al generar cupon, intente mas tarde." + e.message);

          this.preload_out();
        });

    },

    valida_total() {

      if (this.monto_pagar > this.detalle_deuda.monto_deuda || this.monto_pagar > this.monto_maximo_cupon) {
        if (this.detalle_deuda.monto_deuda >= this.monto_maximo_cupon) {
          this.monto_pagar = this.monto_maximo_cupon
        } else {
          this.monto_pagar = this.detalle_deuda.monto_deuda
        }
      }
    },

    consultar_detalle_deuda() {
      this.axios
        .post("/server/", {
          a: "dutDeu",
          b: this.numero_deuda,
          fsrange: this.rmatch,
          bkey: this.rmatch,
        })
        .then((r) => {
          if (r.data.status == 1) {
            if (r.data.data[0].detalle == "") {
              r.data.data[0].detalle = "...";
            }

            this.monto_maximo_cupon=r.data.limite;
            r.data.data[0].monto_deuda = parseFloat(r.data.data[0].monto_deuda);

            console.log(r.data.data[0].monto_deuda,this.monto_maximo_cupon)
            if (r.data.data[0].monto_deuda < this.monto_maximo_cupon) {
              this.monto_pagar = r.data.data[0].monto_deuda;
            } else {
              this.monto_pagar = this.monto_maximo_cupon;
            }

          

            this.detalle_deuda = r.data.data[0];
            this.detalle_deuda.cuotas=r.data.cuotas;
 

          } else if (r.data.status == 404) {
            window.location.href = "/bp";
          } else {
            window.location.href = "/pagos";
          }
        }).catch((e) => {

          alert("Error al consultar deuda. Intentelo mas tarde." + e.message);
        });
    },
  },
};
