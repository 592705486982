import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index.vue";
import servicios from "../views/servicios/index.vue";
import drive from "../views/drive/index.vue";
import botonPago from "../views/botonPago/index.vue";
import registro from "../views/registro/index.vue";
import recupero from "../views/recupero/index.vue";
import otros from "../views/otros/index.vue";
import registroOkv from "../views/registroOk/index.vue";
import registrofsv from "../views/registrofs/index.vue";
import pagosv from "../views/gestionDeuda/pagos/index.vue";
import detalleDeudas from "../views/gestionDeuda/detalleDeudas/index.vue";
import recuperar from "../views/recuperar/index.vue";
import userKey from "../views/userKey/index.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/drive",
    name: "Drive",
    component: drive,
  },
  {
    path: "/servicios/:id",
    name: "servicios",
    component: servicios,
  },
  {
    path: "/bp",
    name: "botonPago",
    component: botonPago,
  },
  {
    path: "/registro",
    name: "registro",
    component: registro,
  },
  {
    path: "/recupero",
    name: "recupero",
    component: recupero,
  },
  {
    path: "/otros",
    name: "otros",
    component: otros,
  },
  {
    path: "/rok",
    name: "registroOkv",
    component: registroOkv,
  },
  

  { path: "/rfs/:tipe", component: registrofsv },


  {
    path: "/pagos",
    name: "pagosv",
    component: pagosv,
  },
  {
    path: "/userKey",
    name: "userKey",
    component: userKey,
  },



  { path: "/detalle/:id", component: detalleDeudas },

  { path: "/RTS/:id", component: recuperar },

];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
