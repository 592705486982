<template>
  <div class="home">
    <barra />
      <serviciosMain />
      
  </div>
</template>

<script>
import barra from "@/components/barra/index.vue";
import serviciosMain from "@/components/serviciosMain/index.vue";


export default {
  name: "servicios",
  components: {
    serviciosMain,
    barra
  },
};
</script>
