export default {
  name: "recupero",
  props: {
    msg: String,
  },
  data() {
    return {

      recup:0,

      data_form: {
        dni: "",
        email: "",
      },
      control_elements: [false, false],
    };
  },

  mounted() {
 
  },

  methods: {

    disabled_in(){
     return(this.data_form.dni=='' || this.data_form.email=='');

    },
  
    recuperar() {
     
      if (this.validador()) {
        this.preload_in();
        this.axios
          .post("/server/", {
            a: "recup",
            b: this.rmatch,
            bkey: this.rmatch,
            f: this.data_form,
          })
          .then((response) => {
           this.recup=response.data.status;

            this.preload_out();
          })
          .catch((err) => {
            this.preload_out();
            alert("Error al procesar la peticion." + err);
          });
      }
    },

    validador() {
      return this.data_form.dni != "" && this.data_form.email != "";
    },

 


  


  },
};
