export default {
  name: "deudas",

  data() {
    return {
      deudas: [],
      empresas: [],
      selected_emp:"" ,
      monto_maximo_cupon:0,
    };
  }, 
  mounted() {
    this.consultar_empresas(); 
  },

  methods: {
 
  

    consultar_empresas() {
      this.axios
        .post("/server/", {
          a: "empcli",
          b: this.rmatch,
          fsrange: this.rmatch,
          bkey: this.rmatch,
        })
        .then((r) => {
          if (r.data.status == 1) {
            this.empresas = r.data.data;

            this.selected_emp=this.empresas[0].empresa

            this.consultar_deudas(this.selected_emp)
          }else if(r.data.status==404){
            window.location.href = "/bp";
          }
        }).catch((e)=>{

          alert("Error al consultar deudas. Intentelo mas tarde."+e.message);
        });
    },

    consultar_deudas(emp) {
      this.axios
        .post("/server/", {
          a: "dudscl",
          b: this.rmatch,
          fsrange: this.rmatch,
          bkey: this.rmatch,
          emp:emp,
        })
        .then((r) => {
          if (r.data.status == 1) {
            let dfeudas = r.data.data;

            for (let i = 0; i < dfeudas.length; i++) {
              dfeudas[i].monto_deuda = parseFloat(dfeudas[i].monto_deuda);

              dfeudas[i].monto_deuda_sttring = this.formatterNumber.format(
                dfeudas[i].monto_deuda
              );
            }

            this.deudas = dfeudas;
          }else if(r.data.status==404){
            window.location.href = "/bp";
          }
        }).catch((e)=>{

          alert("Error al consultar deudas. Intentelo mas tarde."+e.message);
        });
    },
  },

 
};
