<template>
  <div class="home">
    <barra />

    <main id="main">
      <login />
    </main>

    
  </div>
</template>

<script>
import barra from "@/components/barra/index.vue";
import login from "@/components/botonPago/login/index.vue";

export default {
  name: "botonDePago",
  components: {
    login,
    barra,
  },
};
</script>
