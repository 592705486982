<template>
  <div class="home">
    <barra />
    <hero />
    <main id="main">
      <about />
      <services />
      <contact />
      <fort />
      <wapp />
    </main>
  </div>
</template>

<script>
import barra from "@/components/barra/index.vue";
import hero from "@/components/hero/index.vue";
import about from "@/components/about/index.vue";
import services from "@/components/services/index.vue";

import contact from "@/components/contact/index.vue";
import fort from "@/components/footer/index.vue";

import wapp from "@/components/wapp/index.vue";

export default {
  name: "Home",
  components: {
    barra,
    hero,
    about,
    services,
    contact,
    fort,
    wapp,
  },
};
</script>
