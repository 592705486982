<template>
  <div class="home">
    <barra />

    <main id="main">
      <deudas/>
    </main>

    
  </div>
</template>

<script>
import barra from "@/components/pagos/barra/index.vue"; 
import deudas from "@/components/pagos/deudas/index.vue"; 

export default {
  name: "pagosv",
  components: {
 
    barra,
    deudas
  },
};
</script>
